import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import IconClose from "../../../../static/icon-close.svg";
import { getBaseTrackByUrl } from "../../../../helper/module";
import Dialog from "@material-ui/core/Dialog";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import BottomSheet from 'bottom-sheet-react';
import sign_pod from "../../../../static/sign_pod.svg";


const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      width: "100vw",
      height: "100vh",
      backgroundColor: "#00000066",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showpoper: false,
    };
  }

  handleCollapse = () => {
    this.setState({
      showpoper: !this.state.showpoper,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        {" "}
        <span
          className="POD"
          style={{ ...this.props.style }}
          onClick={this.handleCollapse}
        >
          <img src={sign_pod} />
          <span className="dswregrfregtre">
          {String(this.props.title).toUpperCase()}
              </span>
        </span>

        <InnerDialoge showpoper={this.state.showpoper} handleCollapse={this.handleCollapse}>
 
                <div style={{ width: "100%", height: "100%" }}>
                  <div className="podheader">
                    <img
                      src={IconClose}
                      className="close"
                      onClick={this.handleCollapse}
                    />
                    <span className="textheader">{this.props.t(this.props.title)} </span>
                  </div>

                  <div className="podNote">
                    <span className="title"> {this.props.t("Notes")} </span>

                    <span className="note">
                      { this.props.orderType == "Ondemand" ? (this.props.driverDescription &&
                      this.props.driverDescription.length > 0
                        ? this.props.driverDescription
                        : this.props.t("Nonotes")) : (
                          this.props.podNote &&
                      this.props.podNote.length > 0
                        ? this.props.podNote
                        : this.props.t("Nonotes")
                        )}
                    </span>
                  </div>

                  <div className="podSignContainer">
                    <span className="title"> {this.props.t("Photo")} </span>
                    <div className="image">
                      {this.props.podPhoto && this.props.podPhoto.length > 0 ? (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          src={ this.props.podPhoto}
                        />
                      ) : (
                        <span className="nophotosign">
                          {" "}
                          {this.props.t("Nophoto")}{" "}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="podSignContainer">
                    <span className="title"> {this.props.t("Signature")} </span>
                    <div className="image">
                      {this.props.podSignature &&
                      this.props.podSignature.length > 0 ? (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          src={ this.props.podSignature}
                        />
                      ) : (
                        <span className="nophotosign">
                          {" "}
                          {this.props.t("Nosignature")}{" "}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                </InnerDialoge>
      </React.Fragment>
    );
  }
}


function InnerDialoge(props) {
  if (!isDesktop && !isTablet) {
    return (
      props.showpoper ? 
      <BottomSheet
        isExpandable={false}
        customHeight={328}
        onClose={props.handleCollapse}
        backdropStyle={{background:"rgb(13 13 13 / 55%)"}}
        containerStyle={{borderRadius:"8px"}}
      >
        {props.children}
      </BottomSheet>
      :
      null
    );
  } else {
    return (
      <Dialog open={props.showpoper}
      disableAutoFocus
      disableEnforceFocus 
      disableRestoreFocus 
      >
      <Paper
        style={{ width: "376px", height: "325px", borderRadius: "8px",overflow:"hidden" }}>
        <ClickAwayListener onClickAway={props.handleCollapse}>
            {props.children}
          </ClickAwayListener>
        </Paper>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null

  });
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
