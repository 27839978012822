import OnroORG from "./onroORG";
import Fab from "./Fab";
import Loco from "./loco";
import Cartingo from "./cartingo";
import Pickup from "./pickup";
import Onro from "./onro";
import Deliro from "./deliro";
import YONI from "./YONI";
import Emrazgo from "./emrazgo";
import Hellocourier from "./hellocourier";
import Vee from "./vee";
import Oto from "./oto";
import Munchi from "./Munchi";
import HiO from "./HiO";
import Geenstress from "./Geenstress";
import TEZRAFTAAR from "./TEZRAFTAAR";
import PickGo from "./PickGo";
import Wadelivery from "./wadelivery";
import ZoomCurier from "./ZoomCurier";
import Swiftee from "./Swiftee";
import Sanic from "./Sanic";
import QuickSend from "./QuickSend";
import BESwifty from "./BESwifty";
import VroomVroom from "./VroomVroom";
import ASKERI from "./ASKERI";
import DMO from "./DMO";
import DLX from "./DLX";
import STRIKEAFRICA from "./STRIKEAFRICA";
import Asankar from "./Asankar";
import Superkiwi from "./Superkiwi";
import Annanow_pro from "./Annanow (Production)";
import Annanow_stage from "./Annanow (Stage)";
import WeeDeliver from "./WeeDeliver";
import Kwikdash from "./kwikdash";
import Cityspider from "./Cityspider";
import JRUN from "./JRUN";
import Sprint from "./Sprint";
import Flech from "./Flech";
import AOffer from "./AOffer";
import CarRecovery from "./CarRecovery";
import Latreqi from "./latreqi";
import GetItPicked from "./GetItPicked";
import Floti from "./floti";
import FAM from "./Fam";
import SwiftitExpress from "./Swiftit Express";
import KerryExpress from "./Kerry Express";
import Rappid from "./Rappid";
import Flyto from "./Flyto";
import KerryExpress_Instance from "./Kerry Express Instance";
import Bristol from "./Bristol";
import clarabiofuels from "./ClaraBiofuels";
import SCOOTY from "./SCOOTY";
import NOBODI from "./NOBODI";
import Gripx from "./Gripx";
import BIG_TIC_IT from "./BIG_TIC_IT";
import onroORG from "./onroORG";
import couriers24 from "./couriers24";
import JenaTrucks from "./JenaTrucks";
import Geshikt from "./Geshikt";
import SkiptheQ from "./SkiptheQ";
import ParcelBeep from "./ParcelBeep";
import Maydaycourier from "./Maydaycourier";
import PinkExpress from "./PinkExpress";
import Reflexcourier from "./Reflexcourier";
import Snapbox from "./Snapbox";
import Anypoint from "./anypoint";
import Thetruckingforme from "./thetruckingforme";
import Privitrides from "./privitrides";
import BeyondDelivery from "./beyond-delivery";
import Mannaev from "./mannaev";
import landeber from "./landeber";
import Jora from "./JORA";
import CouriersExpress from "./CouriersExpress";
import Swishr from "./Swishr";
import Ondelivery from "./Ondelivery";
import Kwikr from "./Kwikr";
import LoadmanExpress from "./LoadmanExpress";
import EaglePost from "./eaglepost";
import onroORG2 from "./onroORG2";
import Solutions1000 from "./Solutions1000";
import PickNDrop from "./PickNDrop";
import EzDelivery from "./EzDelivery";




export const setGlobalSettings = () => {
    switch (window.location.hostname) {
        case "app.curier-local.ro" : return ZoomCurier(); break;
        case "app.fab.com.np": return Fab() ;break;
        case "deliver.loco.direct": return Loco();break;
        case "restaurant.catrin-go.com": return Cartingo() ;break;
        case "app.pickup.global": return Pickup() ;break;
        case "app.onro.io": return Onro() ;break;
        case "app.onro.org": return OnroORG() ;break;
        case "2app.onro.org": return onroORG2() ;break;
        case "app.deliro.ir": return Deliro();break;
        case "app.yoni-app.sn": return YONI() ;break;
        case "app.emrazgo.com": return Emrazgo() ;break;
        case "order.hellocourier.co.ke": return Hellocourier() ;break;
        case "now.veedelivery.com": return Vee() ;break;
        case "app.otokh.com": return Oto();break;
        case "localhost": return OnroORG() ;break;
        case "delivery.gomunchi.com": return Munchi() ;break;
        case "app.hio.ro":return HiO() ;break;
        case "app.geenstress.amsterdam": return Geenstress() ;break;
        case "business.trcs.pk": return TEZRAFTAAR();break;
        case "deliver.pickngosg.com": return PickGo() ;break;
        case "my.wadelivery.com": return Wadelivery() ;break;
        case "dashboard.swiftee.co.uk": return Swiftee() ;break;
        case "app.saniclogistics.com": return Sanic() ;break;
        case "app.quicksend.ug": return QuickSend() ;break;
        case "app.beswifty.co.uk": return BESwifty() ;
        case "app.vroomvroom.ph": return VroomVroom() ;
        case "members.askeriglobal.com": return ASKERI() ;
        case "app.dmoapp.in": return DMO() ;
        case "customer.dlxapp.online": return DLX() ;
        case "app.strikeapp.link": return STRIKEAFRICA() ;
        case "app.asankar.co": return Asankar() ;
        case "app.sklogistics.do": return Superkiwi() ;
        case "app.anna-now.com": return Annanow_pro() ;
        case "app.stage.anna-now.com": return Annanow_stage()
        case "app.weedeliver.live": return WeeDeliver()
        case "app.kwikdash.co": return Kwikdash()
        case "app.rybit-cityspider.com": return Cityspider()
        case "app.j-run.az": return JRUN()
        case "app.sprint.xyz": return Sprint()
        case "app.flech.io": return Flech()
        case "app.aofferonline.com": return AOffer()
        case "app.aladden.org": return CarRecovery()
        case "app.3latreqi.com": return Latreqi()
        case "app.getitpicked.com": return GetItPicked()
        case "portal.floti.io": return Floti()
        case "webapp.famvalet.com": return FAM()
        case "account.swiftitexpress.com": return SwiftitExpress()
        case "app.kerryexpress.ph": return KerryExpress()
        case "dashboard.tryrappid.com": return Rappid()
        case "webapp.flyto.fi": return Flyto()
        case "app.automoto.ph": return KerryExpress_Instance()
        case "book.bristolcountycouriers.com": return Bristol()
        case "app.clarabiofuels.com": return clarabiofuels()
        case "app.scooty.gr": return SCOOTY()
        case "dashboard.nobodi.com": return NOBODI()
        case "customer.gripx.ph": return Gripx()
        case "app.bigtic-it.com": return BIG_TIC_IT()
        case "app.24couriers.co.uk": return couriers24()
        case "app.jenatrucks.com": return JenaTrucks()
        case "app.geshikt.be": return Geshikt()
        case "app.skiptheq.com": return SkiptheQ()
        case "app.parcelbeep.com": return ParcelBeep()
        case "app.maydaycourier.com": return Maydaycourier()
        case "app.pink.express": return PinkExpress()
        case "app.reflexcourier.ca": return Reflexcourier()
        case "app.snapboxjo.com": return Snapbox()
        case "app.anypoint.live": return Anypoint()
        case "app.thetruckingforme.com": return Thetruckingforme()
        case "app.privitrides.com": return Privitrides()
        case "webapp.beyond-delivery.com": return BeyondDelivery()
        case "client.mannaev.com": return Mannaev()
        case "app.landeber.com": return landeber()
        case "app.joradelivery.com": return Jora()
        case "app.couriersexpress.com": return CouriersExpress()
        case "dashboard.swishrcourier.com": return Swishr()
        case "app.ondelivery.us": return Ondelivery()
        case "app.kwikr.com": return Kwikr()
        case "app.loadmanexpress.com": return LoadmanExpress()
        case "app.eaglepost.com": return EaglePost()
        case "app.1000solutions.ae": return Solutions1000()
        case "app.pickndrop.io": return PickNDrop()
        case "app.ezdeliveryservices.com": return EzDelivery()

        default: return OnroORG()  ;
    }
};

setGlobalSettings()