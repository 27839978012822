export const PerssianLabel = {
  Cutomerpanel: "پنل مشتری",
  lastmile: "Last Mile",
  DeliverySoftware: "Delivery Software",
  login: "ورود",
  Register: "ثبت نام",
  en: "انگلیسی",
  fa: "فارسی",
  Person: "کاربری",
  Business: "تجاری",
  Phonenumber: "شماره تلفن",
  Continue: "ادامه",
  phonenumberhasnotregisterd: "شماره موبایل ثبت نشده است",
  Username: "نام کاربری",
  Password: "رمز عبور"  ,
  ExOnro:"مثلا . آنرو" ,
  Exstart:"مثلا. ****",
  googleConnected:"حساب گوگل متصل شد" ,
  facebookConnected:"حساب فیسبوک متصل شد" ,
  appleConnected:"حساب اپل متصل شد" ,
  plsenterphonnumber:"لطفا شماره تلفن خود را وارد کنید" ,
  firstnnameloginplaceholder:"مثل. رضا" ,
  lastnameloginplaceholder:"مثل. محمدی" ,
  Firstname :"نام" ,
  title:"عنوان",
  Lastname :"نام خانوادگی",
  email:"ایمیل",
  emailPlaceholder :"(اختیاری) مثلا. example@example.com" ,
  comingsoon:"به زودی :)",
  comingsoondesc: "برای ثبت شغل خود لطفا اینجا را کلیک کنید",
  comingsoondesc2 : "لطفا برای ساخت حساب تجاری با ما تماس بگیرید" ,

  contactUs: "با ما تماس بگیرید",  Verificationcode:"کد تایید" ,
  VerificationcodeText :"کد ارسال شده به xxx را وارد کنید",
  VerificationcodePlaceHolder:"مثلا. 1234" ,
  resendCode:"ارسال دوباره" ,
  resendCodeWithTime:"ارسال دوباره (xxx)" ,
  Nointernetconnection:"عدم اتصال به اینترنت" ,
  "close" :"بستن",
  SmsLimitationError:"لطفا چند لحظه ی دیگه دوباره تلاش کنید",
  CustomerAlreadyRegistered:"مشتری قبلاً ثبت نام کرده است ,لطفا وارد شوید",
  registerCompleted:"کد تایید را وارد کنید",
  googletokenerror :"مشکل در توکن گوگل",
  facebooktokenerror :"مشکل در توکن فیسبوک",
  appletokenerror :"مشکل در توکن اپل",
  welcome:"خوش آمدید" ,
  Usernotfound:"اطلاعات شما پیدا نشد",
  VerificationCodeExpired:"کد تایید منقضی شده است",
  InvalidVerificationCode:"کد تایید اشتباه است",
  CustomerNotRegistered:"کاربر ثبت نام نشده است",
  Pending: "در انتظار",
  Unassigned: "واگذار نشده",
  Assigned: "در حال انجام",
  PickedUp: "در حال انجام",
  Started: "در حال انجام",
  statusInProgress: "در حال انجام",
  statusCanceled: "لغو",
  Done: "اتمام",
  CustomerCanceled: "لغو ",
  DriverCanceled: "لغو ",
  SupportCanceled: "لغو ",
  Arrived: "در حال انجام",
  new:"جدید",
  Create_your_first_requst:"یک سفارش جدید ایجاد کنید" ,
  List_of_you_active_orders_will_be_here:"اینجا لیست درخواست های شما نمایش داده میشود",
  otherdropoffs:"مقصد های دیگر" ,
  Orderhistory:"تاریخچه درخواست",
  Noorder:"درخواستی وجود ندارد",
  Historyofyourorderswillbehere:"تاریخچه ی درخواست های شما اینجا نمایش داده میشود",
  loadingList :"در حال دریافت اطلاعات , صبور باشید",
  loadingError:"مشکل در دریافت اطلاعات" ,
  retry :"دوباره تلاش کنید",
  Aboutus:"درباره ما",
  Readour :"ما را بخوانید" ,
  privaypolicy:"سیاست حفظ حریم خصوصی " ,
  Transactions:"تراکنش ها",
  NoTransaction:"شما تراکنشی ندارید",
  Historyofyourransactionswillbehere:"تاریخچه ی تراکنش های شما اینجا نمایش داده میشود",
  Settings:"تنظیمات",
  Language:"زبان",
  en:"English",
  fa:"فارسی",
  fr:"فرانسوی",
  English:"انگلیسی",
  Persian:"فارسی",
  French:"فرانسوی",
  Logout:"خروج",
  logotTitle :"خروج از حساب کاربری؟",
  logoutDescription:" برای ورود مجدد باید با استفاده از نام کاربری و رمز عبور خود اقدام کنید",
  confirmLogout:"بله، خروج",
  skipLogout:"لغو",
  savesettings:"ذخیره تغییرات" ,
  emailInvalid:"ایمیل نامعتبر است",
  Neworder:"درخواست جدید",
  Feedback:"بازخورد",
  Service:"بسته",
  poperLoading: "لطفا صبر کنید",
  Pickup: "مبدا",
  SetPickup:"انتخاب مبدا",
  ChooseoOnMap:"نقشه",
  Fornow: "برای الان",
  Scheduled:"رزرو",
  before:"قبل از :",
  after:"بعد از :",
  confirm:"تایید",
  Settime:"انتخاب زمان",
  for:"برای ",
  Cancel: "بیخیال",
  Shipmentlabel:"برچسب حمل و نقل",

  Pleasetryanotherkeyword: "لطفا کلید واژه دیگری را امتحان کنید",
  Noinformationfound: "اطلاعاتی یافت نشد",
  address: "آدرس",
  block: "بلوک/ساختمان",
  Floor: "طبقه",
  Unit: "واحد",
  senderfullname: "نام کامل فرستنده",
  senderphonenumber: "شماره تلفن فرستنده",
  notesfordriver: "یادداشت برای راننده",
  DropOff: "مقصد",
  SetDropOff:"مقصد مبدا",
  fetchingdata: "درحال دریافت اطلاعات...",
  Recieverfullname: "نام کامل گیرنده",
  Recieverphonenumber: "شماره تلفن گیرنده",
  Vehicletype:"سرویس تایپ" ,
  PromotionCode:"کد تخفیف" ,
  Delete:"حذف",
  Save:"ذخیره",
  PromoCodeValid:"کد تخفیف ثبت شد",
  SenderWallet:"کیف پول",
  SenderCash:"نقدی",
  ReceiverCash:"پرداخت با گیرنده",
  lowBalance:"عدم موجودی کافی",
  Balance:"موجودی",
  Methods:"روش های پرداخت",
  balanceError:"موجودی شما باید حداقل به میزان هزینه درخواست باشد",
  SendOrder:"ارسال درخواست" ,
  Orderagain:"تکرار درخواست" ,
  feedback:"ثبت نظر",
  Createdsuccessfully:"با موفقیت انجام شد",
  chooseAmount:"مقدار را انتخاب کنید" ,
  orenterthepreferedamount:"مقدار پیشنهادی:",
  enteramount :"مقدار را وارد کنید",
  pay:"پرداخت",
  selectPaymentGateway:"درگاه پرداخت را انتخاب کنید",
  Time_MachineText : "آیا مایل به بازیابی اطلاعات درخواست ثبت نشده ی قبلی خود هستید ؟",
  yes:"بله" ,
  ignore:"رد کردن" ,
  Order:"درخواست" ,
  Pending: "در انتظار",
  Unassigned: "واگذار نشده",
  Assigned: "در حال انجام",
  PickedUp: "در حال انجام",
  Started: "در حال انجام",
  statusInProgress: "در حال انجام",
  statusCanceled: "لغو",
  Done: "اتمام",
  CustomerCanceled: "لغو ",
  DriverCanceled: "لغو ",
  SupportCanceled: "لغو ",
  Arrived: "در حال انجام",
  statusPending: "در انتظار",
  statusUnassigned: "واگذار نشده",
  statusAssigned: "واگذار شده",
  statusStarted: "در حال انجام",
  statusPickedUp: "در مبدا",
  statusDone: "انجام شده",
  statusCustomerCanceled: "لغو توسط مشتری",
  statusDriverCanceled: "لغو توسط راننده",
  statusArrived: "رسیدن به مبدا",
  statusSupportCanceled: "لغو توسط پشتیبانی",
  Phone:"شماره تلفن" ,
  additonalservice:"سرویس های اضافی",
  CallDriver:"تماس با راننده",
  Message:"پیام",
  fetchingdata: "درحال دریافت اطلاعات...",
  orderInfoError: "خطا در دریافت اطلاعات",
  support:"پشتیبانی",
  map:"نقشه",
  receipt:"رسید" ,
  photo:"تصویر",
  ServicePhoto:"تصویر بسته",
  Addphoto:"افزودن تصویر",
  Estime: "زمان تخمینی",
  Espath: "مسافت تخمینی",
  pod: "اثبات تحویل",
  Notes: "یادداشت",
  Photo: "عکس",
  Signature: "امضا",
  Nonotes: "بدون یادداشت",
  Nosignature: "بدون امضا",
  Nophoto: "بدون عکس",
  Price: "قیمت",
  Receiver: "توسط گیرنده",
  Sender: "توسط فرستنده",
  Balance: "اعتبار",
  Wallet: "اعتبار",
  Cash: "نقد",
  Copylink: "کپی کردن لینک",
  repeatOrder:"تکرار درخواست",
  Edit: "ویرایش",
  Cancel: "بیخیال",
  confirmCancel: "بله ,لغو درخواست",
  Canceltravel: "لغو درخواست؟",
  paymentmethod: "شیوه پرداخت",
  SucceessFul:"با موفقیت انجام شد",
  errorinServer:"مشکل در سرور",
  Comment:"دیدگاه",
  Exfeedbackinput:"مثلا. بسیار مودب بودند",
  Canceltravel: "لغو درخواست؟",
  CancellText: "بعد از لغو، درخواست برای راننده و مشتری لغو می شود ",
  Estimated: "تخمینی",
  Wallett:'کیف پول' ,
  TypeHere:"اینجا بنویسید",
  Loadingmessages:"در حال دریافت پیام ها",
  PoweredBy:"قدرت گرفته از  ",
  newincomemessage:"شما یک پیام جدید دریافت کردید",
  newincomemessageanother:"شما یک پیام جدید در درخواست دیگری دارید",
  youhaveunreadmessage:"پیام خوانده نشده",
  seen:"خوانده شده" ,
  controlThatbeforeLoweEqualAfter:`زمان  "قبل از :" نمیتواند کوچیکتر از  "بعد از :" باشد` ,
  controlThatbeforeAndAfterMustBiggerthanCurrentDate : `زمان  "قبل از :" و  "بعد از :" نمیتواند از زمان فعلی کوچیکتر باشد`,
  errorInPanelSetting:"خطا در تنظیمات رزرو , لطفا با پشتیبانی تماس بگیرید",
  Arabic:"عربی",
  ar:"عربی",
  Schedule:"رزرو",
  Date : "تاریخ",
  Time:"زمان",
  noDateToShow:"تاریخی برای نمایش وجود ندارد",
  noTimeToShow:"هیچ زمانی برای نمایش وجود ندارد" ,
  Apply:"تایید",
  Increaseyourbalance:"اعتبار خود را افزایش دهید",
  AddBalance:"افزایش اعتبار",
  Addressbook:"دفترچه آدرس",
  AddressbookSearchbar:"جستجو بر اساس آدرس، عنوان و شناسه",
  Removedefault:"حذف پیش فرض",
  Setdefaultpickup:"تنظیم پیش فرض مبدا",
  Edit:"ویرایش کردن" ,
  Remove :"برداشتن",
  newAddress :"آدرس جدید",
  NewAddressbookInfoTitle:"اطلاعات",
  Addressdetails:"جزییات آدرس",
  Title :"عنوان",
  Code:"رمز",
  editAddress :"آدرس ویرایش",
  AddressbookDropOf:"کتاب آدرس (مقصد)",
  AddressbookPickup:"دفترچه آدرس (مبدا)",
  hide:"پنهان شدن",
  Nosavedaddress:"آدرسی ذخیره نشده",
  NosavedaddressDesc:"شما می توانید یک، با کلیک بر روی نماد نشانه در کنار آدرس یا رفتن به آدرس صفحه کتاب ایجاد کنید",
  Romanian:"اهل رومانیایی",
  ro:"اهل رومانیایی" ,
  Pickup2:"سوار کردن" ,
  Dropoff2:"مقصد" ,
  senderfullname: "نام فرستنده",
  block: "بلاک",
  senderphonenumber: "شماره فرستنده",
  Recieverfullname: "نام گیرنده",
  Recieverphonenumber: "شماره گیرنده",


  
  Events: "مناسبت ها",
  allEvents: "همه رویدادها",
  star :"star" ,
  Yourrate:"نرخ شما" ,
  Comment:"اظهار نظر",
  CopyOrderlink:"کپی لینک درخواست",
  CopyPickuplink:"کپی لینک برداشت" ,
  CopyDeliverylink:"کپی لینک تحویل",
  Dutch:"هلندی",
  nl:"هلندی",

  paymentmethod: "روش پرداخت",
  Name :"نام",
  CashOnDelivery:"پرداخت نقدی هنگام تحویل",
  EnterAmount:"مقدار را وارد کنید",
  COD:"پرداخت نقدی هنگام تحویل",
  Surchargexxx:"اضافه کردن xxx",
  Pricedetails: "جزئیات قیمت",
  minxxx :"xxx دقیقه",
  toPickup:"تا مبدا" ,
  toDropOff: "تا مقصد",
  AddCreditCard:"کارت اعتباری / بدهی را اضافه کنید" ,
  CustomerWallet:"کیف پول مشتری" ,
  Add:"اضافه کردن",
  Addedsuccessfully: "با موفقیت اضافه شد",
  paymentmethods: "روش های پرداخت",
  NoCards:"بدون کارت",
  Listofyourcardswillbehere:"فهرست کارت های شما اینجا خواهد بود",
  CardInfo:"اطلاعات کارت",
  ExpireDate:"تاریخ انقضا",
  CVC:"cvc",
  PostalCode:"کد پستی",
  RemoveCardq:"کارت را حذف کنید؟",
  RemoveCardA:"کارت حذف خواهد شد و دیگر نمی تواند مورد استفاده قرار گیرد",
  YesRemove:"بله، حذف کنید",
  Cardisinuse:"کارت در حال استفاده است",
    ok:"خوب",
    Pleasetypeyourwordstosearch:"لطفاً برای جستجو کلمه کلیدی خود را تایپ کنید",
    spanish:"اسپانیایی",
    es : "اسپانیایی",
  Invoices:"فاکتورها",
  noInvoice:"xxx شماره",
  germany: 'آلمان',
  de: 'آلمان',
  downloadClientApplication:"بارگیری برنامه xxx" ,
  AssignedAt: 'تا XXX اختصاص دهید',
  StartedAt: 'تا xxx شروع کنید',
  ArrivedAt: 'تا xxx وارد شوید',
  PickedUpAt: 'وانت در xxx',
  DoneAt: 'در xxx به پایان برسید',
  Russian:"روسی",
  Azerbaijani:"اذکانی",
  rus:"روسی",
  aze:"اذکانی",
  Support:"پشتیبانی",
  SendRequest:"فرستادن درخواست",
  CallTheSupport:"با پشتیبانی تماس بگیرید",
  Choosethesubject :"موضوع را انتخاب کنید",
  Seefrequentquestions:"سوالات مکرر را ببینید",
  Details:"جزئیات",
  Send:"ارسال",
  MessageBox:"صندوق پیام",
  NoMessages:"هیچ پیامی وجود ندارد",
  Yourmessageswillbehere:"پیام های شما اینجا خواهد بود",
  Portuguese:"پرتغالی",
  pt:"پرتغالی",
  Required:"ضروری",
  changePassword : "رمز عبور را تغییر دهید",
  changePasswordp1 : "برای محافظت از حساب خود ، هرکسی که سعی در دستیابی به آن دارد ، از همه جلسات خارج خواهید شد",
  changePasswordp2 : "رمز ورود شما باید حداقل 6 نویسه باشد",
  currentPassword:"رمز عبور فعلی",
  newpassword:"رمز عبور جدید",
  Retypenewpassword:"رمز ورود جدید را دوباره تایپ کنید",
  account: "حساب (کیف پول)",
  "Greek":"یونانی",
  "el":"یونانی",
  "registerBussinessUsername":"نام کاربری (حداقل 5 نویسه)",
  "registerBussinessPassword":"رمز عبور (حداقل 6 نویسه)",
  "ReferenceID":"شناسه مرجع",
  "EnterId":"یک سند هویت ارائه دهید",
  "Orderid": "شماره سفارش",
  "EnterCode":"رمز را وارد کنید",
  "AustrianGerman":"آلمانی اتریشی",
  "deu":"آلمانی اتریشی",
  "pop":"اثبات وانت",
  "Options":"گزینه ها",
  "RequiredText":"ضروری",
  "PleaseSelect":"لطفا انتخاب کنید",
  "Optimizedropoffs":"بهینه سازی قطره ها",
  "Optimizedropoffs?":"بهینه سازی قطره؟",
  "OptimizedropoffsDesc":"دنباله قطره ها به دنباله بهینه شده تغییر می یابد و ممکن است قیمت را تغییر دهد",
  "Optimize":"بهینه کردن"
  ,
  "Bulkimport":"واردات انبوه",
  "processing":"در حال پردازش" ,
   "done":"تمام شده" ,
   "failed":"ناموفق",
   "Draft":"پیش نویس" ,
   "Uploading":"بارگذاری" ,
   "Draganddropyourfile":"پرونده خود را بکشید و رها کنید",
   "Onlycsvformatissupported":"فقط فرمت .csv پشتیبانی می شود" ,
   "ORyoucan":"یا شما می توانید",
   "Openfile":"پرونده باز" ,
   "Noimportsyet":"هنوز وارداتی نیست" ,
   "NoimportsyetDesc":"می توانید لیست واردات خود را در اینجا مشاهده کرده و وضعیت آنها را بررسی کنید",
   "nosupportdialogeTitle":"قالب پرونده پشتیبانی نمی شود",
   "nosupportdialogeText":"پرونده xxx پشتیبانی نمی شود.فقط می توانید پرونده .csv را از جمله اطلاعات سفارش بارگذاری کنید" ,
   "Import":"وارد كردن",
   "previewTitle":"پیش نمایش و گزینه ها را انتخاب کنید",
   "previewDesc":"این اولین مورد از پرونده های شما برای بررسی اطلاعات شما در ساختار مناسب است" ,
   "Upload":"بارگذاری", 
   "Remove":"برداشتن" ,
   "ServiceandOrdertype":"نوع خدمات و سفارش" ,
   "Fileisproccessing":"پرونده در حال پردازش است ...",
   "proccessingDesc":"می توانید تازه کنید که نتیجه آماده است",
   "serverError":"خطای سرور",
   "isRequired":"XXX مورد نیاز است" ,
   "Result":"نتیجه",
   "ResultText":"شما می توانید مشکلات را در اینجا مشاهده کرده و این ردیف ها را برای رفع جداگانه بارگیری کنید",
   "ErrorDetails":"سفارشات با خطا",
   "ErrorDetailsText":"سفارشات با خطاها در پرونده ای که می توانید در زیر بارگیری کنید ذکر شده است.این شامل جزئیات خطا برای هر ردیف است.",
   "DownloadErroredRows":"بارگیری ردیف های اشتباه" ,
   "importedsuccessfully":"سفارشات XXX با موفقیت وارد می شوند" ,
   "ordershaserrors":"سفارشات XXX خطایی دارد",
   "refresh":"تازه کردن",
   "pleaseSellectParsel":"لطفا سرویس را انتخاب کنید",
   "Ondemand":"بر اساس تقاضا",
   "PickupandDelivery":"وانت و تحویل",
   "PickupDelivery":"وانت و تحویل",
   "Delivery":"تحویل",
   "Pickup":"سوار کردن",
   "tab_Delivery":"تحویل",
   "tab_Ondemand":"بر اساس تقاضا",
   "tab_Pickup":"سوار کردن",
   "tab_PickupDelivery":"P&D",
   "Downloadsamplecsv":"بارگیری نمونه CSV" ,
   "driverPhoto":"Driver Photo",
   "yourPhoto":"Your Photo",
   "UploadPhoto":"Upload Photo",
   "Photos":"Photos",
   
   
   
   "TrackLink":"Track Link",
   "ShipmentLabel":"Shipment Label",
   "Draft":"Draft",
   "Receipt":"Receipt",
   "Confirm":"Confirm",
   "Cancel":"Cancel",
   "BulkEditStatusSummary":"Bulk Edit Status Summary",
   "Successful":"Successful",
   "Failed":"Failed",
   "OrderID":"Order ID",
   "Details":"Details",
   "Result":"Result",
   "New":"New",
   "Import":"Import",
   "Apply":"Apply",
   "Clear":"Clear",
   "Noresultfound":"No result found",
   "Typeyourkeyword":"Type your keyword",
   "Typeintheinputtostartsearch":"Type in the input to start search",
   "OnDemand":"On Demand",
   "P_Hub_D":"P_Hub_D",
   "AddressDetails":"Address Details",
   "Map":"Map",
   "Support":"Support",
   "BulkEditStatusxxx/yyy":"Bulk Edit Status xxx/yyy",
   "xxxSuccessfull,yyyFailed":"xxx Successfull, yyy Failed",
   "xxxfromyyy":"xxx from yyy",
   "xxxrows":"xxx rows",
   "xxxselected":"xxx selected",
   "xxxdropoffs":"+xxx dropoffs",
   "Status Updated at xxx":"Status Updated at xxx",
   'More':'More',
   'Summary':'Summary',
   'Prev':'Prev',
   'Next':'Next',
   'Done':'Done',
   "ClearAll":"Clear All",
   'Errorinloadingdata':'Error in loading data',
   'Therewasaproblemloadingdatapleasetryagainorcontactsupport':'There was a problem loading data. please try again or contact support',
   'Tryagain':'Try again',
   'ConfigTable' :'Config Table' ,
   'ActiveColumns':'Active Columns',
   'Createyourorder':'Create your order',
   'Yourorderswillbeshownhere':'Your orders will be shown here',
   'Pleasecheckyourfiltersorkeyword':'Please check your filters or keyword',
};
