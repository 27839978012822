import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../static/chevron-down.svg";
import pickup from "../../../../static/icon-ic-pickup.svg";
import drop from "../../../../static/icon-ic-dropoff.svg";
import pickupDropDone from "../../../../static/pickup_done.svg";

import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import { PickupDone } from "../../../../helper/module";
import jalali from "jalali-moment";
import momentJalaali from "moment-jalaali";
import { get_lang } from "../../../../helper/UserData/userdate";
import { ReactComponent as Sion } from "../../../../static/Mutual___ic_calender.svg";
import moment from 'jalali-moment'
import Pod from "./pod";
import styles2 from './viewOrder.module.css';
import Typography from "../../../../design-system/lib/Typography";
import Pop from "./pop";
import Service_photo from "./service_photo";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      pDialoge: false,
    };
    this.anchorEl = React.createRef();
  }

  handleCollapse = () => {
    this.props.handleCollapse(this.state.id);
  };

  render() {
    const { t } = this.props;
    let reserveIsValid = this.props.pickup
      ? (this.props.pickup.scheduleDateBefore
        ? momentJalaali(this.props.pickup.scheduleDateBefore).isValid()
        : false) ||
      (this.props.pickup.scheduleDateAfter
        ? momentJalaali(this.props.pickup.scheduleDateAfter).isValid()
        : false)
      : false;

    let scheduleDateBeforeIsValid = this.props.pickup
      ? this.props.pickup.scheduleDateBefore > 0
        ? momentJalaali(this.props.pickup.scheduleDateBefore).isValid()
        : false
      : false;

    let scheduleDateAfterIsValid = this.props.pickup
      ? this.props.pickup.scheduleDateAfter > 0
        ? momentJalaali(this.props.pickup.scheduleDateAfter).isValid()
        : false
      : false;
    return (
      <vrpasserngerContainer
        style={
          {
            /*  paddingTop: reserve reserveIsValid ? "8px" : "16px" */
          }
        }
        className="pickupCustomized"
      >
        <vrpickuptitle
          style={{ width: "calc(100% - 16px)" }}
          onClick={this.handleCollapse}
        >


          {/* {PickupDone(this.props.status) ? (
            <img className="squree doneAddress" src={pickupDropDone} />
          ) : (
            <img className="squree" src={pickup} />
          )} */}

          {!pickup_isPickuped(this.props.status) && <vrPickupCircle />}
          {pickup_isPickuped(this.props.status) && <img src={pickupDropDone} className="puci" />}

          <span className="title">
            {" "}
            <Trans i18nKey={"Pickup2"}> </Trans>{" "}
            {this.props.pickup ? (this.props.pickup.scheduleDateAfter ||
              this.props.pickup.scheduleDateBefore ? (
              <div className="scheduledOrderTimeConatiner">
                <Sion className="img" />
                <span>
                  {/* {moment(Number(
                    this.props.pickup.scheduleDateAfter ||
                      this.props.pickup.scheduleDateBefore
                  )).locale(get_lang()).format("D MMM hh:mm")} */}

                  {momentJalaali(Number(
                    this.props.pickup.scheduleDateAfter ||
                    this.props.pickup.scheduleDateBefore
                  )).locale(get_lang()).format("D MMM HH:mm")}

                  {/* {new Date(Number(
                    this.props.pickup.scheduleDateAfter ||
                    this.props.pickup.scheduleDateBefore
                  )).toLocaleString(get_lang(), {
                    hour: "2-digit",
                    minute: "2-digit",
                    month: 'long',
                    day: 'numeric',
                    hour12:false
                  })
                  } */}

                  {/* {new Date(
                  Number(
                    this.props.pickup.scheduleDateAfter ||
                      this.props.pickup.scheduleDateBefore
                  )
                ).toLocaleDateString(get_lang(), {
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hourCycle: 'h23'
                })} */}
                </span>
              </div>
            ) : null) : null}
          </span>

          <div className={styles2.rowButtonsContainer}>
            <span className={` copey`}>
              {this.props.pickup && this.props.pickup.address}
            </span>
            {(this.props.orderType == "PickupDelivery") && (
              <Pop popNote={this.props.pureData?.pickup?.popNote}  popSignature={this.props.pureData?.pickup?.popSignature} popPhoto={this.props.pureData?.pickup?.popPhoto} title={"pop"} />
            )}

            {(this.props.orderType == "Ondemand" && (this.props.service && this.props.service.setting.enableCustomerPhoto)) && <Service_photo show={this.state.pDialoge} service={this.props.service} />}


            <img
              onClick={this.handleCollapse}
              src={arrow}
              className={`vrpickupCollIconVR ${this.props.activeCollapse === this.state.id && "rotate180"
                }`}
                style={{marginInlineStart:"0px"}}
            />
          </div>


          {/* <span className={`miniAddressVR`}>
            {this.props.pickup && this.props.pickup.address}
          </span> */}

          {/* {reserveIsValid ? (
            <span className={`miniAddressVR ViewOrderReservText`}>
              {this.props.t("for") + "  "+ 
                (this.props.pickup.scheduleDateAfter
                  ? momentJalaali(
                      Number(this.props.pickup.scheduleDateAfter)
                    ).format(
                      get_lang() == "fa"
                        ? "HH:mm jYYYY/jMM/jDD"
                        : "YYYY/MM/DD HH:mm"
                    )
                  : "") +
                (this.props.pickup.scheduleDateAfter &&
                this.props.pickup.scheduleDateBefore
                  ? " - "
                  : "") +
                (this.props.pickup.scheduleDateBefore
                  ? momentJalaali(
                      Number(this.props.pickup.scheduleDateBefore)
                    ).format(
                      get_lang() == "fa"
                        ? "HH:mm jYYYY/jMM/jDD"
                        : "YYYY/MM/DD HH:mm"
                    )
                  : "")}
            </span>
          ) : null} */}

        </vrpickuptitle>


        {this.props.pickup && this.props.pureData && (
          this.props.orderType == "PickupDelivery" ? <Collapse
            className="pickupcollapsecontainervr"
            in={this.props.activeCollapse === this.state.id}
            timeout="auto"
            unmountOnExit
          >

            <div className={styles2.pickup_dropOff_collapseRowContainer}>
              <div className={styles2.pickup_dropOff_collapseRow}>
                <div className={styles2.pickup_dropOff_collapseSubRow}>
                  <Typography weight="Body_Tiny_Medium" text={this.props.pickup.addressDetail.length > 0
                    ? this.props.pickup.addressDetail
                    : "-"} style={{ color: "#242424" }} />
                  <Typography weight="Body_Tiny_Regular" text={"Address Details"} style={{ color: "#808080" }} />
                </div>
              </div>
              <div className={styles2.pickup_dropOff_collapseRow}>
                <div className={styles2.pickup_dropOff_collapseSubRow}>
                  <Typography weight="Body_Tiny_Medium" text={this.props.pickup.fullName.length > 0
                    ? this.props.pickup.fullName
                    : "-"} style={{ color: "#242424" }} />
                  <Typography weight="Body_Tiny_Regular" text={this.props.t("senderfullname")} style={{ color: "#808080" }} />
                </div>
                <div className={styles2.pickup_dropOff_collapseSubRow}>
                  <Typography weight="Body_Tiny_Medium" text={this.props.pickup.phone.length > 0
                    ? (String(this.props.pickup.phone).startsWith("+") ?
                      this.props.pickup.phone :
                      (String(this.props.pickup.phone).startsWith("00")
                        ? "+" + String(this.props.pickup.phone).slice(2, this.props.pickup.phone.length) : ""))
                    : "-"} style={{ color: "#242424" }} />
                  <Typography weight="Body_Tiny_Regular" text={this.props.t("senderphonenumber")} style={{ color: "#808080" }} />
                </div>
                <div className={styles2.pickup_dropOff_collapseSubRow}>
                  <Typography weight="Body_Tiny_Medium" text={this.props.pickup.email.length > 0
                    ? this.props.pickup.email
                    : "-"} style={{ color: "#242424" }} />
                  <Typography weight="Body_Tiny_Regular" text={this.props.t("email")} style={{ color: "#808080" }} />
                </div>
              </div>
            </div>


          </Collapse> :
            <Collapse
              className="pickupcollapsecontainervr"
              in={this.props.activeCollapse === this.state.id}
              timeout="auto"
              unmountOnExit
            >


              <div className="vrPickupCollapseDetail">
                <span className="xyu">
                  {" "}
                  {this.props.pickup.buildingBlock.length > 0
                    ? this.props.pickup.buildingBlock
                    : "-"}{" "}
                </span>
                <span className="xyt"> {this.props.t("block")} </span>
              </div>
              <div className="vrPickupCollapseDetail">
                <span className="xyu">
                  {" "}
                  {this.props.pickup.floor.length > 0
                    ? this.props.pickup.floor
                    : "-"}{" "}
                </span>
                <span className="xyt"> {this.props.t("Floor")} </span>
              </div>

              <div className="vrPickupCollapseDetail">
                <span className="xyu">
                  {" "}
                  {this.props.pickup.room.length > 0
                    ? this.props.pickup.room
                    : "-"}{" "}
                </span>
                <span className="xyt"> {this.props.t("Unit")} </span>
              </div>

              <div className="vrPickupCollapseDetail">
                <span className="xyu">
                  {" "}
                  {this.props.pickup.fullName.length > 0
                    ? this.props.pickup.fullName
                    : "-"}{" "}
                </span>
                <span className="xyt"> {this.props.t("senderfullname")} </span>
              </div>

              <div className="vrPickupCollapseDetail" style={{}}>
                <span className="xyu">
                  {" "}
                  {this.props.pickup.phone.length > 0
                    ? (String(this.props.pickup.phone).startsWith("+") ?
                      this.props.pickup.phone :
                      (String(this.props.pickup.phone).startsWith("00")
                        ? "+" + String(this.props.pickup.phone).slice(2, this.props.pickup.phone.length) : ""))
                    : "-"}{" "}
                </span>
                <span className="xyt"> {this.props.t("senderphonenumber")} </span>
              </div>

              <div className="vrPickupCollapseDetail">
                <span className="xyu">
                  {" "}
                  {this.props.pickup.email.length > 0
                    ? this.props.pickup.email
                    : "-"}{" "}
                </span>
                <span className="xyt"> {this.props.t("email")} </span>
              </div>



              <div className="vrPickupCollapseDetail">
                <span className="xyu">
                  {" "}
                  {this.props.pickup.customerDescription.length > 0
                    ? this.props.pickup.customerDescription
                    : "-"}{" "}
                </span>
                <span className="xyt"> {this.props.t("notesfordriver")} </span>
              </div>


            </Collapse>
        )}
      </vrpasserngerContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.ViewTravel.pureData ? state.ViewTravel.pureData.status : "",
  pickup: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.pickup
      ? state.ViewTravel.pureData.pickup
      : null
    : null,
    service: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.service
      ? state.ViewTravel.pureData.service
      : null
    : null,

  pureData: state.ViewTravel.pureData ? state.ViewTravel.pureData : null,
  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));


export const pickup_isPickuped = (status) => {
  switch (status) {
    case "PickedUp": return true; break;
    case "Done": return true; break;
    case "Transit": return true; break;
    default: return false;
  }
};
