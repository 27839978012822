export const AustrianGermanLabel = {
  "Cutomerpanel": "Kunden Portal",
  "login": "Login",
  "Register": "Registrieren",
  "en": "Englisch",
  "fa": "Deutsch",
  "Person": "Privatperson",
  "Business": "Geschäftskunde",
  "Phonenumber": "Mobil-Nummer",
  "Continue": "Weiter",
  "phonenumberhasnotregisterd": "Mobil-Nummer ist nicht registriert.",
  "Username": "Benutzername",
  "title": "Titel",
  "Password": "Passwort",
  "ExOnro": "z.B. Firmenname",
  "Exstart": "Passwort",
  "googleConnected": "Google connected",
  "facebookConnected": "Facebook connected",
  "appleConnected": "Apple ID connected",
  "plsenterphonnumber": "Bitte füge Deine Mobil-Nummer ein.",
  "firstnnameloginplaceholder": "z.B. Max",
  "lastnameloginplaceholder": "z.B. Mustermann",
  "Firstname": "Vorname",
  "Lastname": "Nachname",
  "email": "E-Mail-Adresse",
  "emailPlaceholder": "E-Mail-Adresse",
  "comingsoon": "kommt bald:",
  "comingsoondesc": "Kontaktiere uns, um ein Firmenkonto zu eröffnen.",
  "CustomerAlreadyRegistered": "Kunde ist schon registriert - bitte zum Login",
  "registerCompleted": "Verifikationscode eingeben",
  "googletokenerror": "Problem mit dem Google Tokken",
  "facebooktokenerror": "Problem mit dem Facebook Tokken",
  "appletokenerror": "Problem mit den Apple Tokken",
  "CustomerNotRegistered": "Kunde ist noch nicht registriert",
  "fr": "Französisch",
  "de": "Deutschland",
  "InvalidVerificationCode": "Ungültiger Verifikationscode",
  "a": "Deine Information konnte nicht gefunden werden.",
  "welcome": "Willkommen",
  "SmsLimitationError": "Bitte versuche es in einigen Minuten nochmals.",
  "resendCodeWithTime": "Code erneut schicken (xxx)",
  "resendCode": "Code erneut schicken",
  "VerificationcodePlaceHolder": "z.B. 1234",
  "Verificationcode": "Verifikationscode",
  "VerificationcodeText": "Bitte den gesendeten Code eingeben (xxx).",
  "VerificationCodeExpired": "Verifikationscode abgelaufen",
  "Pending": "Ausstehend",
  "Unassigned": "Nicht zugewiesen",
  "Assigned": "Zugewiesen",
  "PickedUp": "Abgeholt",
  "Started": "Gestartet",
  "statusInProgress": "Im Laufen",
  "Done": "Erledigt",
  "CustomerCanceled": "Vom Kunden storniert",
  "DriverCanceled": "Vom Fahrer storniert",
  "SupportCanceled": "Vom Support storniert",
  "Arrived": "Zugestellt",
  "new": "Neu",
  "Historyofyourorderswillbehere": "Die Auftragshistorie wird hier angezeigt.",
  "otherdropoffs": "Weitere Adressen",
  "Orderhistory": "Auftragshistorie",
  "Noorder": "Kein Auftrag",
  "loadingList": "Bitte Geduld, wir laden die Information.",
  "loadingError": "Problem mit dem Laden der Information",
  "retry": "Versuche es nocheinmal",
  "Create_your_first_requst": "Einen neuen Auftrag erfassen",
  "List_of_you_active_orders_will_be_here": "Die Liste der aktiven Aufträge wird hier gezeigt.",
  "Aboutus": "Über uns",
  "Readour": "Lese unsere",
  "privaypolicy": "Datenschutzerklärung",
  "Transactions": "Transaktionen",
  "NoTransaction": "Es gibt keine Transaktionen",
  "Historyofyourransactionswillbehere": "Die Transaktionshistorie wird hier gezeigt.",
  "Settings": "Einstellungen",
  "Language": "Sprachen",
  "English": "Englisch",
  "Persian": "Persisch",
  "French": "Französisch",
  "Logout": "Ausloggen",
  "logotTitle": "Vom Konto ausloggen?",
  "logoutDescription": "Du wirst vom Konto ausgeloggt und kannst später wieder einloggen.",
  "confirmLogout": "Ja, ausloggen",
  "skipLogout": "Schliessen",
  "savesettings": "Einstellungen speichern",
  "emailInvalid": "E-Mail-Adresse ungültig",
  "Neworder": "Neuer Auftrag",
  "Service": "Serviceart",
  "poperLoading": "Bitte warten",
  "Pickup": "Abholadresse",
  "SetPickup": "Abholadresse anklicken",
  "ChooseoOnMap": "Auf der Karte auswählen",
  "Fornow": "Absofort",
  "Scheduled": "Geplant",
  "before": "Vor:",
  "after": "Nach:",
  "confirm": "Bestätigen",
  "Settime": "Zeitfenster",
  "fo": "für",
  "Cancel": "Cancel",
  "Pleasetryanotherkeyword": "Versuche ein anderes Keyword",
  "Noinformationfound": "Keine Information gefunden",
  "address": "Adresse",
  "block": "Stiege",
  "Floor": "Stockwerk",
  "Unit": "Türnummer",
  "senderfullname": "Versendername",
  "senderphonenumber": "Mobilnummer",
  "notesfordriver": "Notiz für den Fahrer",
  "DropOff": "Zustelladresse",
  "SetDropOff": "Zustelladresse anklicken",
  "fetchingdata": "Empfange Daten",
  "Recieverfullname": "Empfängername",
  "Recieverphonenumber": "Mobilnummer",
  "undefined": "Fahrzeugtypen",
  "PromotionCode": "Promotionscode",
  "Delete": "Löschen",
  "Save": "Speichern",
  "PromoCodeValid": "Promotionscode gespeichert",
  "SenderWallet": "Konto",
  "SenderCash": "Bar",
  "ReceiverCash": "Bar (Vom Empfänger)",
  "lowBalance": "Guthaben zu klein",
  "Balance": "Konto",
  "Methods": "Zahlungsmethode",
  "balanceError": "Ihr Guthaben muss mindestens dem Auftrag entsprechen",
  "SendOrder": "Auftrag absenden",
  "feedback": "Bewertung",
  "Feedback": "Bewertung",
  "Orderagain": "Nochmals bestellen",
  "Createdsuccessfully": "Erfolgreich erfasst",
  "chooseAmount": "Betrag auswählen",
  "orenterthepreferedamount": "Bevorzugter Betrag:",
  "enteramount": "Gewünschter Betrag",
  "pay": "Bezahlen",
  "selectPaymentGateway": "Wähle den Zahlungsanbieter",
  "Time_MachineText": "Möchtest Du die offene Auftagsinformation wiederherstellen?",
  "yes": "Ja",
  "ignore": "Ignorieren",
  "Order": "Auftrag",
  "statusPending": "Ausstehend",
  "statusUnassigned": "Nicht zugewiesen",
  "statusAssigned": "Zugewiesen",
  "statusStarted": "Gestartet",
  "statusPickedUp": "Abgeholt",
  "statusDone": "Erledigt",
  "statusCustomerCanceled": "Storniert vom Kunden",
  "statusDriverCanceled": "Storniert vom Fahrer",
  "statusArrived": "Angekommen",
  "statusSupportCanceled": "Gelöscht vom Support",
  "statusCanceled": "Storniert",
  "Phone": "Mobilnummer",
  "additonalservice": "Zusätzliches Service",
  "CallDriver": "Fahrer anrufen",
  "Message": "Nachricht",
  "orderInfoError": "Fehler beim Empfang der Daten",
  "support": "Support",
  "map": "Karte",
  "receipt": "Beleg",
  "photo": "Foto",
  "ServicePhoto": "Foto",
  "Addphoto": "Foto hinzufügen",
  "Estime": "Geschätzte Zeit",
  "Espath": "Vorgeschlagene Route",
  "pod": "Zustellnachweis",
  "Notes": "Notizen",
  "Photo": "Foto",
  "Signature": "Unterschrift",
  "Nonotes": "Keine Notiz",
  "Nosignature": "Keine Unterschrift",
  "Nophoto": "Kein Foto",
  "Price": "Preis",
  "Receiver": "Vom Empfänger",
  "Sender": "Vom Versender",
  "Cash": "Bar",
  "Canceltravel": "Anfrage stornieren",
  "CancellText": "Nach dem Stornieren wird auch der Auftrag beim Fahrer gelöscht",
  "Estimated": "Geschätzt",
  "Wallet": "Saldo",
  "Copylink": "Link kopieren",
  "repeatOrder": "Auftrag wiederholen",
  "Edit": "Ändern",
  "confirmCancel": "Ja",
  "paymentmethod": "Zahlungsmethode",
  "SucceessFul": "Erfolgreich abgeschlossen",
  "errorinServer": "Serverproblem",
  "Comment": "Kommentar",
  "Exfeedbackinput": "z.B. bin top zufrieden, verlässlich ausgeliefert",
  "Wallett": "Konto",
  "TypeHere": "Hier eingeben",
  "Loadingmessages": "Nachrichten werden geladen",
  "PoweredBy": "Powered by PiNK.Express",
  "newincomemessage": "Eine neue Nachricht erhalten",
  "newincomemessageanother": "Eine neue Nachricht in einem anderen Auftrag erhalten",
  "youhaveunreadmessage": "Ungelesene Nachrichten",
  "Vehicletype": "Fahrzeugtyp",
  "Pickup2": "Abholadresse",
  "Dropoff2": "Zustelladresse",
  "Events": "Ereignis",
  "allEvents": "Alle Ereignisse",
  "star": "Sterne",
  "Yourrate": "Bewertung",
  "CopyOrderlink": "Auftragslink kopieren",
  "CopyPickuplink": "Abhollink kopieren",
  "CopyDeliverylink": "Zustelllink kopieren",
  "Nointernetconnection": "Keine Internetverbindung",
  "close": "Schliessen",
  "seen": "Gesehen",
  "controlThatbeforeLoweEqualAfter": "Die Zeit \"Vor\" kann nicht kleiner als \"Nach\" sein",
  "controlThatbeforeAndAfterMustBiggerthanCurrentDate": "Die \"Vor\" und \"Nach\" Zeiten können nicht kleiner als die aktuelle Zeit sein",
  "errorInPanelSetting": "Fehler bei der Reservierung, bitte kontaktiere den Support",
  "Arabic": "Arabisch",
  "ar": "Arabisch",
  "veeDeliveryLoginText1": "Send Anything",
  "veeDeliveryLoginText2": "To Anyone, NOW!",
  "Schedule": "Geplant / Terminiert",
  "Date": "Datum",
  "Time": "Zeit",
  "noDateToShow": "Kein Datum zum Anzeigen",
  "noTimeToShow": "Keine Zeit zum Anzeigen",
  "Apply": "Anwenden",
  "Increaseyourbalance": "Guthaben erhöhen",
  "AddBalance": "Guthaben hinzufügen",
  "Addressbook": "Adressbuch",
  "AddressbookSearchbar": "Suchen (nach Adresse oder Bezeichnung)",
  "Removedefault": "Suchen (nach Adresse oder Bezeichnung)",
  "Setdefaultpickup": "Als Standard Abholadresse setzen",
  "Remove": "Löschen",
  "newAddress": "Neue Adresse",
  "NewAddressbookInfoTitle": "Info",
  "Addressdetails": "Adressdetails",
  "Title": "z.B. Firmenname, Empfänger",
  "Code": "z.B. Kundennummer (Optional)",
  "editAddress": "Adresse ändern",
  "AddressbookDropOf": "Adressbuch (Zustelladressen)",
  "AddressbookPickup": "Adressbuch (Abholadressen)",
  "hide": "Verbergen",
  "Nosavedaddress": "Keine gespeicherten Adressen",
  "NosavedaddressDesc": "Auftragserfassung durch Icon-Klick im Adressbuch",
  "Romanian": "Romanisch",
  "ro": "Romanisch",
  "Dutch": "Holländisch",
  "Shipmentlabel": "Versandlabel",
  "Name": "Namen",
  "CashOnDelivery": "Per  Nachnahme",
  "EnterAmount": "Betrag eingeben",
  "COD": "Per  Nachnahme",
  "Surchargexxx": "Zuschläge xxx",
  "Pricedetails": "Kostenaufstellung",
  "minxxx": "xxx min",
  "toPickup": "zur Abholadresse",
  "toDropOff": "zur Zustelladresse",
  "AddCreditCard": "Karte hinzufügen",
  "CustomerWallet": "Kundenkonto",
  "Add": "Hinzufügen",
  "Addedsuccessfully": "Erfolgreich hinzugefügt",
  "paymentmethods": "Zahlungsmethode",
  "NoCards": "Keine Karten",
  "Listofyourcardswillbehere": "Die Liste der Karten wird hier angezeigt",
  "CardInfo": "Card Info",
  "ExpireDate": "Expire date",
  "CVC": "CVC",
  "PostalCode": "PLZ",
  "RemoveCardq": "Karte entfernen?",
  "RemoveCardA": "Die Karte wird entfernt und kann nicht mehr verwendet werden.",
  "YesRemove": "Ja, entfernen",
  "Cardisinuse": "Karte ist in Verwendung",
  "ok": "Ok",
  "Pleasetypeyourwordstosearch": "Bitte tippe dein Keyword zum suchen ein",
  "spanish": "Spanisch",
  "Invoices": "Rechnung",
  "noInvoice": "Rechnungsnr. Xxx",
  "germany": "Deutschland",
  "nl": "Niederländisch",
  "downloadClientApplication": "Download xxx app",
  "AssignedAt": "Zugeteilt seit xxx",
  "StartedAt": "Gestartet seit xxx",
  "ArrivedAt": "Zugestellt seit xxx",
  "PickedUpAt": "Abgeholt seit xxx",
  "DoneAt": "Erledigt seit xxx",
  "Support": "Support",
  "SendRequest": "Anfrage senden",
  "CallTheSupport": "Support anrufen",
  "Choosethesubject": "Wählen Sie das Thema",
  "Seefrequentquestions": "FAQ",
  "Details": "Details",
  "Send": "Senden",
  "MessageBox": "Nachrichten",
  "NoMessages": "Keine Nachrichten",
  "Yourmessageswillbehere": "Ihre Nachrichten werden hier sein",
  "ReferenceID": "Referenz ID",
  "EnterId": "Lieferschein Nr, Kundenname",
  "individual": "Individuell",
  "comingsoondesc2": "Um ein Geschäftskonto zu erstellen, kontaktieren Sie uns bitte",
  "contactUs": "kontaktiere uns",
  "es": "Spanisch",
  "Russian": "Russisch",
  "Azerbaijani": "Aserbaidschani",
  "rus": "Russisch",
  "aze": "Aserbaidschani",
  "Portuguese": "Portugiesisch",
  "pt": "Portugiesisch",
  "Greek": "griechisch",
  "el": "griechisch",
  "changePassword": "Kennwort ändern",
  "changePasswordp1": "Sie werden alle Sitzungen verlassen, mit Ausnahme davon, um Ihr Konto zu schützen, wer versucht, zugreifen zu können",
  "changePasswordp2": "Ihr Passwort sollte mindestens 6 Zeichen betragen",
  "currentPassword": "Aktuelles Passwort",
  "newpassword": "Neues Kennwort",
  "Retypenewpassword": "Geben Sie erneut ein neues Passwort ein",
  "account": "Konto (Brieftasche)",
  "Required": "Notwendig",
  "registerBussinessUsername": "Benutzername (mindestens 5 Zeichen)",
  "registerBussinessPassword": "Passwort (mindestens 6 Zeichen)",
  "Orderid": "Auftragsnummer",
  "EnterCode": "Code eingeben",
  "AustrianGerman": "Deutsch AT",
  "deu": "Deutsch AT",
  "pop": "Nachweis der Abholung",
  "Options": "Optionen",
  "RequiredText": "Erforderlich",
  "PleaseSelect":"Bitte auswählen",
  "Optimizedropoffs":"Tropfen optimieren",
  "Optimizedropoffs?":"Tropfen optimieren?",
  "OptimizedropoffsDesc":"Die Abfolge der Tropfen wird in eine optimierte Sequenz geändert und kann den Preis ändern",
  "Optimize":"Optimieren",

  "Bulkimport":"Massenimport",
  "processing":"wird bearbeitet" ,
   "done":"Fertig" ,
   "failed":"Fehlgeschlagen",
   "Draft":"Entwurf" ,
   "Uploading":"Hochladen" ,
   "Draganddropyourfile":"Ziehen Sie und lassen Sie Ihre Datei ab",
   "Onlycsvformatissupported":"Nur .CSV -Format wird unterstützt" ,
   "ORyoucan":"Oder du kannst",
   "Openfile":"Datei öffnen" ,
   "Noimportsyet":"Noch keine Importe" ,
   "NoimportsyetDesc":"Sie können die Liste Ihrer Importe hier sehen und ihren Status überprüfen",
   "nosupportdialogeTitle":"Dateiformat nicht unterstützt",
   "nosupportdialogeText":"XXX -Datei wird nicht unterstützt.Sie können nur .csv -Datei einschließlich Bestellinformationen hochladen" ,
   "Import":"Importieren",
   "previewTitle":"Vorschau und wählen Sie Optionen",
   "previewDesc":"Dies ist das erste Element Ihrer Dateien, das Ihre Informationen überprüft, die sich in der richtigen Struktur befinden" ,
   "Upload":"Hochladen", 
   "Remove":"Entfernen" ,
   "ServiceandOrdertype":"Service- und Bestellentyp" ,
   "Fileisproccessing":"Datei ist verarbeitet ...",
   "proccessingDesc":"Sie können aktualisieren, um festzustellen, ob das Ergebnis fertig ist",
   "serverError":"Serverfehler",
   "isRequired":"xxx ist erforderlich" ,
   "Result":"Ergebnis",
   "ResultText":"Sie können die Probleme hier sehen und diese Zeilen herunterladen, um sie separat zu beheben",
   "ErrorDetails":"Bestellungen mit Fehler",
   "ErrorDetailsText":"Bestellungen mit Fehlern sind in der Datei aufgeführt, die Sie unten herunterladen können.Es enthält Fehlerdetails für jede Zeile.",
   "DownloadErroredRows":"Laden Sie fehlerhafte Zeilen herunter" ,
   "importedsuccessfully":"XXX -Bestellungen werden erfolgreich importiert" ,
   "ordershaserrors":"XXX -Bestellungen haben Fehler",
   "refresh":"Aktualisierung",
   "pleaseSellectParsel":"Bitte wählen Sie Service",
   "Ondemand":"Auf Anfrage",
   "PickupandDelivery":"Abholung und Lieferung",
   "PickupDelivery":"Abholung und Lieferung",
   "Delivery":"Lieferung",
   "Pickup":"Abholen",
   "tab_Delivery":"Lieferung",
   "tab_Ondemand":"Auf Anfrage",
   "tab_Pickup":"Abholen",
   "tab_PickupDelivery":"P&D",
   "Downloadsamplecsv":"Beispiel CSV herunterladen" ,
   "driverPhoto":"Driver Photo",
   "yourPhoto":"Your Photo",
   "UploadPhoto":"Upload Photo",
   "Photos":"Photos",
   
   
   
   "TrackLink":"Track Link",
   "ShipmentLabel":"Shipment Label",
   "Draft":"Draft",
   "Receipt":"Receipt",
   "Confirm":"Confirm",
   "Cancel":"Cancel",
   "BulkEditStatusSummary":"Bulk Edit Status Summary",
   "Successful":"Successful",
   "Failed":"Failed",
   "OrderID":"Order ID",
   "Details":"Details",
   "Result":"Result",
   "New":"New",
   "Import":"Import",
   "Apply":"Apply",
   "Clear":"Clear",
   "Noresultfound":"No result found",
   "Typeyourkeyword":"Type your keyword",
   "Typeintheinputtostartsearch":"Type in the input to start search",
   "OnDemand":"On Demand",
   "P_Hub_D":"P_Hub_D",
   "AddressDetails":"Address Details",
   "Map":"Map",
   "Support":"Support",
   "BulkEditStatusxxx/yyy":"Bulk Edit Status xxx/yyy",
   "xxxSuccessfull,yyyFailed":"xxx Successfull, yyy Failed",
   "xxxfromyyy":"xxx from yyy",
   "xxxrows":"xxx rows",
   "xxxselected":"xxx selected",
   "xxxdropoffs":"+xxx dropoffs",
   "Status Updated at xxx":"Status Updated at xxx",
   'More':'More',
   'Summary':'Summary',
   'Prev':'Prev',
   'Next':'Next',
   'Done':'Done',
   "ClearAll":"Clear All",
   'Errorinloadingdata':'Error in loading data',
   'Therewasaproblemloadingdatapleasetryagainorcontactsupport':'There was a problem loading data. please try again or contact support',
   'Tryagain':'Try again',
   'ConfigTable' :'Config Table' ,
   'ActiveColumns':'Active Columns',
   'Createyourorder':'Create your order',
   'Yourorderswillbeshownhere':'Your orders will be shown here',
   'Pleasecheckyourfiltersorkeyword':'Please check your filters or keyword',
};
