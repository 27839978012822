
import SnapboxIGO from "../../../static/SnapboxLogo.png";
import Snapbox_banner from "../../../static/SnapboxBanner.png";
import FavIcon from "../../../static/PROJECTS/Snapbox.png";

export default function Snapbox() {
    
    return  global.config = {
        "project": "Snapbox",
        "ShowPowerOnro": false,
        "flag": "",
        "ProjectName": "Snapbox Delivery",
        "LogoType": SnapboxIGO,
        "BaseApi": "https://rest.snapboxjo.com",
        "BaseSocket": "https://socket.snapboxjo.com",
        "BaseTrack": "https://admin.snapboxjo.com",
        "MapCenter": {
            "lat": "31.340090170314284",
            "lng": "36.76341005649451"
        },
        "Lang": "ar",
        "Direction": "Rtl",
        "AllLanguages": [{
            "lang": "ar",
            "direction": "Rtl",
            "i18Lang": "Arabic",
            "cssFlag": "arabicflag",
            "country": "JO"
        }, {
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/Snapbox.png",
        "Country": "JO",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Snapbox_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: "",
        customColor:"Snapbox",
        registerEnabled: true,
        Secondarycolor: "#061153",
        SecondaryDarkColor: "#050D3E",
        SecondaryLightColor: "#0B1A74",
        SecondaryUltraLight: "#E6E7EE",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#061153",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#ECDE0F",
        icon_light_end:"#EFE116",
        icon_dark_start:"#061153",
        icon_dark_end:"#0B1A74",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#FEFDED",
        loginBackgroundColorEnd:"#F0EBA5",
        LoginTextColor :"#061153",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:SnapboxIGO,
        apkUrl:null,
        iosUrl:null
}}