import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import close from "../../../../static/close.svg";
import Addressbook from "../../../../static/icon-ic-address-book-menu.svg";
import {ReactComponent as NewAddressbook} from "../../../../static/icon-ic-add.svg";
import {
  get_customerPannel_type,
  get_LeftRight,
} from "../../../../helper/UserData/userdate";
import searchicon from "../../../../static/iconly-light-outline-search.svg";
import AddressItem from "./addressItem";
import EditAddress from "./editAddress";
import {
  OnChangeopenAddAddressBookInMenu,
  OnChangeopenAddressBookInMenu,
  OnChangeopenEditAddressBookInMenu,
  OpenCloseMenu,
  SetAddressBookSearchRegex,
  SetDetailsEdit,
  Set_latlng,
} from "../../../../Redux/actions/actionNames";
// import PerfectScrollbar from "perfect-scrollbar";
import { Api_Get_Addressbook_list_AGW, Api_map_geocoding_reverse_AGW } from "../../../../helper/api/ApiGateway";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import {ReactComponent as AddressbookImg} from "../../../../static/icon-ic-address-book-menu.svg";
import { Link } from 'react-router-dom';

var _ = require("lodash");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddressbook: false,
      openNewAddress: false,
      openEditAddress: false,
      CanGetMoreList: true,
    };
    this.ps = null;
    this.loadMore = this.loadMore.bind(this);
  }

  loadMore = () => {
    this.setState({
      CanGetMoreList: false,
    });
    Api_Get_Addressbook_list_AGW(
      this.props.dispatch,
      {
        page: this.props.page,
        perpage: 10,
        searchRegex: this.props.searchRegex,
      },
      true
    );
  };

  componentDidMount() {
    // try {
    //   const container = document.getElementById("AddressBookListMenu");
    //   this.ps = new PerfectScrollbar(container);
    //   container.addEventListener("ps-y-reach-end", () => {
    //    this.state.CanGetMoreList && this.loadMore();
    //   });
    // } catch (error) {}
  }

  componentWillReceiveProps(nextProps) {
    // setTimeout(() => {
    //   if (nextProps.openMenu && this.ps == null && this.props.list.length > 0) {
    //     try {
    //       const container = document.getElementById("AddressBookListMenu");
    //       this.ps = new PerfectScrollbar(container);
    //       container.addEventListener("ps-y-reach-end", () => {
    //         this.loadMore();
    //       });
    //     } catch (error) {}
    //   }
    // }, 500);
  }

  AddressbookSearchbar = (e) => {
    this.props.dispatch(SetAddressBookSearchRegex(e.target.value));

    Api_Get_Addressbook_list_AGW(
      this.props.dispatch,
      { page: 1, perpage: 20, searchRegex: e.target.value },
      false
    );
  };

  handleOpenNewAddress = () => {
    // this.setState({
    //   openNewAddress: !this.state.openNewAddress,
    // });
    this.props.dispatch(OpenCloseMenu(false));
    this.props.dispatch(OnChangeopenAddressBookInMenu(false));
    this.props.dispatch(OnChangeopenAddAddressBookInMenu(true));

    this.handleCenterChanged()

  };

  handleEditAddress = () => {
    this.props.dispatch(OnChangeopenEditAddressBookInMenu(!this.state.openEditAddress))

    this.setState({
      openEditAddress: !this.state.openEditAddress,
    });

    this.handleCenterChanged()
  };


  handleCenterChanged = () => {
    if (this.props.mapRef) {
      const newCenter = this.props.mapRef.getCenter();
      this.props.dispatch(
        Set_latlng({
          lng: newCenter.lng(),
          lat: newCenter.lat(),
        })
      );
      let center = this.props.mapRef.getCenter();


        Api_map_geocoding_reverse_AGW(this.props.dispatch, {
          latitude: center.lat(),
          longitude: center.lng(),
        });
    }
  };

  handleOncloseEditAddress = () => {
    this.props.dispatch(OnChangeopenEditAddressBookInMenu(false))

    this.setState({
      openEditAddress: false,
    });
    this.props.dispatch(
      SetDetailsEdit({
        FullName: "",
        PhoneNumber: "",
        address: "",
        adresss_loc: null,
        block: "",
        buildingBlock: "",
        code: "",
        coordinates:null,
        description: "",
        email: "",
        floor: "",
        fullName: "",
        id: null,
        isDefaultPickupAddress: false,
        phone: "",
        placeId: "",
        room: "",
        title: "1111",
        unit: ""
      })
    );
  };

  openAddressbook = () => {
    // this.setState({
    //   openAddressbook: true,
    // });

    this.props.dispatch(OnChangeopenAddressBookInMenu(true));
  };

  closeAddressbook = () => {
    // this.setState({
    //   openAddressbook: false,
    // });
    this.props.dispatch(OnChangeopenAddressBookInMenu(false));
    this.props.onclose && this.props.onclose()
  };

  AddressbookSearchbar = (e) => {
    this.props.dispatch(SetAddressBookSearchRegex(e.target.value));

    Api_Get_Addressbook_list_AGW(
      this.props.dispatch,
      { page: 1, perpage: 20, searchRegex: e.target.value },
      false
    );
  };


  handleContainerOnBottom = () => {
    this.loadMore();
  };



  render() {
    return (
      <React.Fragment>
        {this.props.ShowComponentMenu ? (
          <div
            className="headerItemContainer"
            onClick={() => this.openAddressbook()}
          >
            <img src={Addressbook} />
            <span className="span">
              {" "}
              <Trans i18nKey={"Addressbook"}> </Trans>{" "}
            </span>
          </div>
        ) : null}
        <Slide
          direction={get_LeftRight()}
          in={this.props.openMenu || this.props.OpenDialoge}
          mountOnEnter
          unmountOnExit
        >
          <div className="AddressbookConatiner">
            <nRHeader style={{ boxShadow: "none" }}>
              <img
                onClick={() => this.closeAddressbook()}
                className="close"
                src={close}
              />
              <span className="title">
                <Trans i18nKey={"Addressbook"}> </Trans>
              </span>

              <Link to={`/`} ><div
                className="newAddressbookContainer"
                onClick={this.handleOpenNewAddress}
              >
                <NewAddressbook className="img"/>
                {/* <img src={newAddressbook} /> */}
                <span>
                  {" "}
                  <Trans i18nKey={"newAddress"}> </Trans>{" "}
                </span>
              </div></Link>
            </nRHeader>

            {(this.props.list.length > 0 ||
              this.props.searchRegex.length > 0) &&
              get_customerPannel_type() == "business" && (
                <div className={`AddressbookSearchbarContainer`}>
                  <img src={searchicon} />
                  <input
                    value={this.props.searchRegex}
                    placeholder={this.props.t("AddressbookSearchbar")}
                    onChange={this.AddressbookSearchbar}
                  />
                </div>
              )}

            {this.props.listLoading && (
              <CircularProgress
                className="AddressbookCircularProgressmenu"
                style={{ position: "absolute" }}
              />
            )}
        {this.props.list.length > 0  &&   <BottomScrollListener onBottom={this.handleContainerOnBottom}>
              {(scrollRef) => (
                <div
                  ref={scrollRef}
                  id="AddressBookListMenu"
                  className="AddressbookMenuListContainer"
                  style={{
                    top: get_customerPannel_type() == "business" ? "123px" : "71px",
                    height:get_customerPannel_type() == "business" ? "calc(100% - 127px)" : "calc(100% - 95px)",
                  }}
                >ُ 
                  {this.props.list.map((item, i) => {
                    return (
                      <AddressItem
                        id={item.id}
                        key={i}
                        onEdit={this.handleEditAddress}
                        AddressData={item}
                      />
                    );
                  })}
                </div>
              )}
            </BottomScrollListener>}
            {this.props.list.length == 0 && !this.props.listLoading && (
              <div className="nosavedaddressContainer">
                {/* <img src={newAddressbook} /> */}
                <AddressbookImg className=" AddressbookImgInCo" style={{transform:"scale(2)",height:"32px",display:"block" ,marginLeft:"auto",marginRight:"auto"}}/>
                <p className="nosavedtitle">
                  {" "}
                  <Trans i18nKey={"Nosavedaddress"}> </Trans>{" "}
                </p>
                <p className="NosavedaddressDesc">
                  {" "}
                  <Trans i18nKey={"NosavedaddressDesc"}> </Trans>{" "}
                </p>
              </div>
            )}
          </div>
        </Slide>

        <EditAddress
          open={this.state.openEditAddress}
          onClose={this.handleOncloseEditAddress}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  openMenu: state.Addressbook.openAddressBookInMenu,
  list: state.Addressbook.list,
  listLoading: state.Addressbook.listLoading,
  listError: state.Addressbook.listError,
  perpage: state.Addressbook.perpage,
  page: state.Addressbook.page,
  searchRegex: state.Addressbook.searchRegex,
  mapRef: state.Map.mapRef,
  center: state.Map.center,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
